import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {toastActions} from './toast'
import {getRandId, readURIFromFile} from '../libs'
import {RoomDesignService} from '../api'
import {currentUserActions} from './currentUser'
import {selectBeforeImageData} from '../selectors'

interface BeforeImage {
    id?: number,
    stubId: number,
    roomType: string,
    lifestyle: string,
    file: File,
    uri: string,
    serverURI: string,
}

interface AfterImage {
    id: number,
    before_image_id: number,
    serverURI: string,
}

const MaxFileCount = 5
const MaxFileSize = 5*1024*1024

const addBeforeImages = createAsyncThunk(
    'addBeforeImages',
    async (files: FileList, thunkAPI: any) => {
        const {beforeImages} = thunkAPI.getState().roomDesign
        let newImages = [...beforeImages]
        for (const file of files) {
            if (file.type != 'image/png' && file.type != 'image/jpeg' && file.type != 'image/webp') continue
            if (file.size >= MaxFileSize) {
                thunkAPI.dispatch(roomDesignActions.setIsPhotoTooBigPopupShow(true))
                continue
            }
            if (newImages.length >= MaxFileCount) break
            const uri = await readURIFromFile(file)
            newImages.push({
                stubId: getRandId(),
                file,
                uri,
            })
        }
        thunkAPI.dispatch(roomDesignActions.setBeforeImages(newImages))
    }
)

const setRoomType = createAsyncThunk(
    'setRoomType',
    async (roomType: string, thunkAPI: any) => {
        const {beforeImages} = thunkAPI.getState().roomDesign
        const selectedBeforeImageData = selectBeforeImageData(thunkAPI.getState())
        const newImages = beforeImages.map((image: BeforeImage) => {
            if (image.stubId != selectedBeforeImageData.stubId) return image
            return {
                ...image,
                roomType,
            }
        })
        thunkAPI.dispatch(roomDesignActions.setBeforeImages(newImages))
    }
)

const setLifestyle = createAsyncThunk(
    'setLifestyle',
    async (lifestyle: string, thunkAPI: any) => {
        const {beforeImages} = thunkAPI.getState().roomDesign
        const selectedBeforeImageData = selectBeforeImageData(thunkAPI.getState())
        const newImages = beforeImages.map((image: BeforeImage) => {
            if (image.stubId != selectedBeforeImageData.stubId) return image
            return {
                ...image,
                lifestyle,
            }
        })
        thunkAPI.dispatch(roomDesignActions.setBeforeImages(newImages))
    }
)

const createRoomDesign = createAsyncThunk(
    'createRoomDesign',
    async (navigate: any, thunkAPI: any) => {
        const {
            beforeImages, 
        } = thunkAPI.getState().roomDesign
        const before_images = beforeImages.map((image: BeforeImage) => image.file)
        const room_types = beforeImages.map((image: BeforeImage) => image.roomType)
        const lifestyles = beforeImages.map((image: BeforeImage) => image.lifestyle)

        thunkAPI.dispatch(roomDesignActions.setIsLoading(true))
        let runTime = 0
        thunkAPI.dispatch(roomDesignActions.setRuntime(runTime))
        const timer = setInterval(() => {thunkAPI.dispatch(roomDesignActions.setRuntime(runTime++))}, 1000)
        const res = await RoomDesignService.createRoomDesign({formData:{room_types, lifestyles, before_images}}) as any
        thunkAPI.dispatch(roomDesignActions.setIsLoading(false))
        clearInterval(timer)
        if (!res.id && res.message == "cannot_generate_photo") {
            thunkAPI.dispatch(roomDesignActions.setIsGeneratedFail(true))
            return
        }
        if (!res.id) {
            thunkAPI.dispatch(roomDesignActions.setIsGeneratedFail(true))
            thunkAPI.dispatch(toastActions.toastError(res.message))
            return
        }
        processServerAfterImages(res, thunkAPI, beforeImages, navigate)
    }
)

const regenAfterImages = createAsyncThunk(
    'regenAfterImages',
    async (navigate: any, thunkAPI: any) => {
        const {
            beforeImages,
        } = thunkAPI.getState().roomDesign
        const selectedBeforeImageData = selectBeforeImageData(thunkAPI.getState())

        thunkAPI.dispatch(roomDesignActions.setIsLoading(true))
        thunkAPI.dispatch(roomDesignActions.subtractMaxRegen())
        thunkAPI.dispatch(roomDesignActions.setSelectedRegenImage(selectedBeforeImageData.stubId))
        let runTime = 0
        thunkAPI.dispatch(roomDesignActions.setRuntime(runTime))
        const timer = setInterval(() => {thunkAPI.dispatch(roomDesignActions.setRuntime(runTime++))}, 1000)
        const res = await RoomDesignService.regenAfterImages({id: selectedBeforeImageData.id}) as any
        thunkAPI.dispatch(roomDesignActions.setIsLoading(false))
        thunkAPI.dispatch(roomDesignActions.setSelectedRegenImage(undefined))
        clearInterval(timer)
        if (!res.id) {
            thunkAPI.dispatch(toastActions.toastError(res.message))
            return
        }
        processServerAfterImages(res, thunkAPI, beforeImages, navigate)
    }
)

function processServerAfterImages(res: any, thunkAPI: any, beforeImages: BeforeImage[], navigate: any) {
    const {id, beforeimages} = res
    thunkAPI.dispatch(roomDesignActions.setIsGeneratedFail(false))
    thunkAPI.dispatch(roomDesignActions.setId(id))
    const newBeforeImages = beforeImages.map((beforeImage: any, index: number) => {
        const serverBeforeImage = beforeimages[index]
        if (!serverBeforeImage) return beforeImage
        return {
            ...beforeImage,
            id: serverBeforeImage.id,
            serverURI: serverBeforeImage.file,
        }
    })
    let afterImages = [] as AfterImage[]
    beforeimages.forEach((beforeimage: any) => {
        beforeimage.afterimages.forEach((afterimage: any) => {
            afterImages.push({
                id: afterimage.id,
                before_image_id: beforeimage.id,
                serverURI: afterimage.file,
            })
        })
    })
    thunkAPI.dispatch(roomDesignActions.setBeforeImages(newBeforeImages))
    thunkAPI.dispatch(roomDesignActions.setAfterImages(afterImages))
    thunkAPI.dispatch(currentUserActions.getCurrentUser(navigate))
}

const initialState = {
    isLoading: false,
    id: undefined,
    selectedBeforeImage: undefined,
    selectedRegenImage: undefined,
    selectedAfterImage: undefined,
    beforeImages: [] as BeforeImage[],
    afterImages: [] as AfterImage[],
    isConfirmPopupShow: false,
    isRoomTypePopupShow: false,
    isLifestylePopupShow: false,
    isClearAllPopupShow: false,
    isGeneratedFail: false,
    isRegenPopupShow: false,
    isPhotoTooBigPopupShow: false,
    maxExecTimePerImage: 35, //seconds
    maxRegenCount: 5,
    runTime: 0,
}

const roomDesignSlice = createSlice({
    name: 'roomDesign',
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setId: (state, action) => {
            state.id = action.payload
        },
        setBeforeImages: (state, action) => {
            state.beforeImages = action.payload
        },
        setSelectedBeforeImage: (state, action) => {
            state.selectedBeforeImage = action.payload
        },
        setSelectedRegenImage: (state, action) => {
            state.selectedRegenImage = action.payload
        },
        removeBeforeImage: (state, action) => {
            const removeStubId = action.payload
            state.beforeImages = state.beforeImages.filter(beforeImage => beforeImage.stubId != removeStubId)
        },
        setAfterImages: (state, action) => {
            state.afterImages = action.payload
        },
        setSelectedAfterImage: (state, action) => {
            state.selectedAfterImage = action.payload
        },
        setIsConfirmPopupShow: (state, action) => {
            state.isConfirmPopupShow = action.payload
        },
        setIsRoomTypePopupShow: (state, action) => {
            state.isRoomTypePopupShow = action.payload
        },
        setIsLifestylePopupShow: (state, action) => {
            state.isLifestylePopupShow = action.payload
        },
        setIsClearAllPopupShow: (state, action) => {
            state.isClearAllPopupShow = action.payload
        },
        setIsGeneratedFail: (state, action) => {
            state.isGeneratedFail = action.payload
        },
        setIsRegenPopupShow: (state, action) => {
            state.isRegenPopupShow = action.payload
        },
        setIsPhotoTooBigPopupShow: (state, action) => {
            state.isPhotoTooBigPopupShow = action.payload
        },
        setRuntime: (state, action) => {
            state.runTime = action.payload
        },
        subtractMaxRegen: (state) => {
            state.maxRegenCount = state.maxRegenCount-1
        },
        setInitState: (state) => {
            return initialState
        },
    },
})

const roomDesignActions = {
    ...roomDesignSlice.actions,
    addBeforeImages,
    setRoomType,
    setLifestyle,
    createRoomDesign,
    regenAfterImages,
}
export {roomDesignActions}
export default roomDesignSlice.reducer