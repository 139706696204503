import { useEffect } from "react"
import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import {useNavigate} from 'react-router-dom'
import { Modal } from "flowbite-react";
import {CloseModalIcon} from './SVGIcons'
import {siteNotiActions} from '../reducers/siteNoti'

function mapStateToProps(state: any) {
  return { ...state.siteNoti }
}

const SiteNotiEdit = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()
  const {isPopupShow, noti} = props

  const closePopup = () => {
    dispatch(siteNotiActions.setInitState())
  }
  const postSiteNoti = () => {
    dispatch(siteNotiActions.postSiteNoti())
  }

  return (
    <>
      <Modal show={isPopupShow} size="md" >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
              <h3 className="text-xl font-medium text-gray-900">通知内容の編集</h3>
              <button type="button" onClick={closePopup}
                className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center">
                <CloseModalIcon />
                <span className="sr-only">モーダルを閉じる</span>
              </button>
            </div>
            <div className="p-4 md:p-5">
              <div className="space-y-4">
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">あなたのコンテンツ<span className="text-red-600 ml-1">*</span></label>
                    <textarea value={noti} onChange={e => dispatch(siteNotiActions.setNoti(e.target.value))} rows={5}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"></textarea>
                </div>
                <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse gap-2 border-t">
                  <button type="button" onClick={postSiteNoti} className="inline-flex w-full justify-center rounded-md bg-blue-700 hover:bg-blue-800 px-3 py-2 text-sm text-white">保存</button>
                  <button type="button" onClick={closePopup} className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">キャンセル</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(SiteNotiEdit)
