import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {roomDesignActions} from '../reducers/roomDesign'
import {RoomType, LifestyleType} from '../constants'
import {getKeyByValue} from '../libs'
import {selectCanChangePageSetting, selectBeforeImageData} from '../selectors'
import pho_living from '../../assets/images/pho_living.png'
import pho_dining from '../../assets/images/pho_dining.png'
import pho_bedroom from '../../assets/images/pho_bedroom.png'
import pho_studio from '../../assets/images/pho_studio.png'
import pho_industrial from '../../assets/images/pho_industrial.webp'
import pho_modern from '../../assets/images/pho_modern.webp'

function mapStateToProps(state: any) {
  const canChangePageSetting = selectCanChangePageSetting(state)
  const selectedBeforeImageData = selectBeforeImageData(state)
  return { ...state.roomDesign, canChangePageSetting, selectedBeforeImageData }
}

const RoomDesignEditRoomTypeLifestyle = (props: any) => {
  let {
    canChangePageSetting,
    selectedBeforeImageData
  } = props
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()
  if (!selectedBeforeImageData) return null
  const {roomType, lifestyle} = selectedBeforeImageData
  let roomTypeImage
  if (roomType == "living")
    roomTypeImage = pho_living
  if (roomType == "dining")
    roomTypeImage = pho_dining
  if (roomType == "bedroom")
    roomTypeImage = pho_bedroom
  if (roomType == "studio")
    roomTypeImage = pho_studio

  let lifestyleImage
  if (lifestyle == "industrial")
    lifestyleImage = pho_industrial
  if (lifestyle == "modern")
    lifestyleImage = pho_modern
  
  const openRoomTypePopup = () => {
    if(!canChangePageSetting) return

    dispatch(roomDesignActions.setIsRoomTypePopupShow(true))
  }
  const openLifestylePopup = () => {
    if(!canChangePageSetting) return
    
    dispatch(roomDesignActions.setIsLifestylePopupShow(true))
  }

  return (
    <div className="mb-2 flex gap-3">
      <div className="flex-1 flex items-center space-x-2">
        <div className="flex-shrink-1">
          <h3 className="mb-1 text-sm flex items-center">
            <strong>部屋タイプ</strong>
            <span className="text-slate-600">をご選択ください。</span>
          </h3>
          <button onClick={openRoomTypePopup} disabled={!canChangePageSetting}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block min-w-44 p-2.5"
            type="button">
            {roomType ? getKeyByValue(RoomType, roomType) : "部屋タイプ"}
          </button>
        </div>
        <div className="w-16 h-16 rounded overflow-hidden">
          <img src={roomTypeImage} className="w-full h-auto object-cover"/>
        </div>
      </div>

      <div className="flex-1 flex items-center space-x-2">
        <div className="flex-shrink-1 relative">
          <h3 className="mb-1 text-sm flex items-center">
            <strong>生活スタイル</strong>
            <span className="text-slate-600">をご選択ください。</span>
          </h3>
          <button onClick={openLifestylePopup} disabled={!canChangePageSetting}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block min-w-44 p-2.5"
            type="button">
            {lifestyle ? getKeyByValue(LifestyleType, lifestyle) : "生活スタイル"}
          </button>
        </div>
        <div className="w-16 h-16 rounded overflow-hidden">
          <img src={lifestyleImage} className="w-full h-auto object-cover"/>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(RoomDesignEditRoomTypeLifestyle)