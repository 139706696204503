import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import {CurrentUserService} from '../api'

const initialState = {
    name: undefined,
    email: undefined,
    is_staff: false,
    creditConsumed: undefined,
    creditLimit: undefined,
    editingEmail: '',
    editingPassword: '',
    showPassword: false,
    error: undefined,
}

const signInUser = createAsyncThunk(
    'signInUser',
    async (navigate: any, thunkAPI: any) => {
        const {editingEmail, editingPassword} = thunkAPI.getState().currentUser
        if (!editingEmail) {
            thunkAPI.dispatch(currentUserActions.setError("メールアドレスが入力されていません。"))    
            return
        }
        if (!editingPassword) {
            thunkAPI.dispatch(currentUserActions.setError("パスワードが入力されていません。"))    
            return
        }
        const trimEmail = editingEmail.trim()
        const res = await CurrentUserService.currentUserLogin({requestBody:{username: trimEmail, password: editingPassword}})
        if (res.success) {
            thunkAPI.dispatch(currentUserActions.setInitState())
            thunkAPI.dispatch(currentUserActions.setEmail(editingEmail))
            navigate("/");
            return
        }

        thunkAPI.dispatch(currentUserActions.setError(res.message))
    },
)

const signOutUser = createAsyncThunk(
    'signOutUser',
    async (navigate: any, thunkAPI: any) => {
        const res = await CurrentUserService.currentUserLogout()
        if (res.success) {
            thunkAPI.dispatch(currentUserActions.setInitState())
            navigate("/login");
            return
        }
    },
)

const getCurrentUser = createAsyncThunk(
    'getCurrentUser',
    async (navigate: any, thunkAPI: any) => {
        const res = await axios.get('/api/get-current-user').catch(() => false) as any
        thunkAPI.dispatch(currentUserActions.setInitState())
        if (!res) {
            navigate("/login");
            return
        }
        const data = res.data
        if (data.id) {
            const {email, is_staff, account} = data
            thunkAPI.dispatch(currentUserActions.setEmail(email))
            thunkAPI.dispatch(currentUserActions.setIsStaff(is_staff))
            const creditConsumed = account ? account.credit_consumed : 0
            thunkAPI.dispatch(currentUserActions.setCreditConsumed(creditConsumed))
            const creditLimit = account ? account.credit_limit : 0
            thunkAPI.dispatch(currentUserActions.setCreditLimit(creditLimit))
            return
        }

        navigate("/login");
    },
)

const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setIsStaff: (state, action) => {
            state.is_staff = action.payload
        },
        setCreditConsumed: (state, action) => {
            state.creditConsumed = action.payload
        },
        setCreditLimit: (state, action) => {
            state.creditLimit = action.payload
        },
        setEditingEmail: (state, action) => {
            state.editingEmail = action.payload
        },
        setEditingPassword: (state, action) => {
            state.editingPassword = action.payload
        },
        switchShowPassword: (state) => {
            state.showPassword = !state.showPassword
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setInitState: (state) => {
            return initialState
        }
    },
})

const currentUserActions = {
    ...currentUserSlice.actions,
    signInUser,
    signOutUser,
    getCurrentUser
}
export {currentUserActions}
export default currentUserSlice.reducer