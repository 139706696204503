import {ThunkDispatch} from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import {connect, useDispatch } from "react-redux"
import {currentUserActions} from '../reducers/currentUser'
import Logo from '../../assets/images/logo.svg'
import {ShowPassword, HidePassword} from './SVGIcons'

function mapStateToProps(state: any) {
    return { ...state.currentUser }
}

const Login = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    const { editingEmail, editingPassword, error, showPassword } = props;
    
    const signInUser = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        dispatch(currentUserActions.signInUser(navigate))
    }
    const switchShowPassword = () => {
        dispatch(currentUserActions.switchShowPassword())
    }

    return (
        <section className="bg-gray-50 h-screen">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="flex items-center mb-5">
                    <img src={Logo} alt="" className="max-h-16"/>
                </div>
                <div className="w-full bg-white rounded-lg shadow-lg md:mt-0 sm:max-w-md xl:p-0">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <form className="space-y-4 md:space-y-6" onSubmit={signInUser}>
                            <div>
                                <input
                                    value={editingEmail} onChange={e => dispatch(currentUserActions.setEditingEmail(e.target.value))}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                    placeholder="ユーザー"
                                />
                            </div>
                            <div className="relative">
                                {!showPassword && (
                                    <input
                                        type="password"
                                        value={editingPassword} onChange={e => dispatch(currentUserActions.setEditingPassword(e.target.value))}
                                        placeholder="パスワード"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 pr-10"
                                    />
                                )}
                                {showPassword && (
                                    <input
                                        value={editingPassword} onChange={e => dispatch(currentUserActions.setEditingPassword(e.target.value))}
                                        placeholder="パスワード"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 pr-10"
                                    />
                                )}
                                <button type="button" onClick={switchShowPassword} className="absolute top-[0.2rem] bottom-[0.2rem] right-[0.2rem] flex items-center px-2 bg-gray-50 rounded-lg text-gray-900">
                                    {!showPassword && (
                                        <ShowPassword />
                                    )}
                                    {showPassword && (
                                        <HidePassword />
                                    )}
                                </button>
                            </div>
                            <div>
                                {error && (
                                    <p className="mt-2 text-sm text-red-600"><span className="font-medium">※</span>{error}</p>
                                )}
                                {error == '契約期限が超過しています。' && (
                                    <>
                                        <p className="mt-2 text-sm text-red-600"><span className="font-medium">※</span>ご不明点がある場合は、
                                            <a className="underline text-blue-700"  target="blank" href="https://docs.google.com/forms/d/e/1FAIpQLSeOnqLutSGFjjvo6Bo0Z9Ulq4vb6tcH8pGA0m39kxA9Z1xAVg/viewform">お問い合わせフォーム</a>までご連絡ください。</p>
                                    </>
                                )}
                            </div>
                            <button
                                type="submit"
                                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-5 py-2.5 text-center">
                                ログイン
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default connect(mapStateToProps)(Login)