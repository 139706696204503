import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {UsersService} from '../api'

const fetchUsers = createAsyncThunk(
    'fetchUsers',
    async (_, thunkAPI: any) => {
        const {usersList} = thunkAPI.getState()
        const {
            page, 
            limit, 
            searchKeyword, 
            contractStartDateSort, 
            contractEndDateSort, 
            corporateNameFilter,
            storeNameFilter,
            registrationRouteFilter,
            planTypesFilter,
            isActiveFilter,
        } = usersList
        const res = await UsersService.usersList({
            page, 
            limit, 
            searchKeyword, 
            contractStartDateSort, 
            contractEndDateSort, 
            corporateNameFilter,
            storeNameFilter,
            registrationRouteFilter,
            planTypesFilter,
            isActiveFilter,
        })
        const {results, page_count} = res
        thunkAPI.dispatch(usersListActions.setItems(results))
        thunkAPI.dispatch(usersListActions.setPageCount(page_count))
    },
)

const doSearchKeywordAndFetch = createAsyncThunk(
    'doSearchKeywordAndFetch',
    async (page: number = 1, thunkAPI: any) => {
        thunkAPI.dispatch(usersListActions.setPage(1))
        thunkAPI.dispatch(usersListActions.fetchUsers())
    },
)

const setPageAndFetch = createAsyncThunk(
    'setPageAndFetch',
    async (page: number = 1, thunkAPI: any) => {
        thunkAPI.dispatch(usersListActions.setPage(page))
        thunkAPI.dispatch(usersListActions.fetchUsers())
    },
)

const switchSortContractStartDate = createAsyncThunk(
    'switchSortContractStartDate',
    async (_, thunkAPI: any) => {
        const {usersList} = thunkAPI.getState()
        const {contractStartDateSort} = usersList
        let newSort
        if (contractStartDateSort == 'none')
            newSort = 'asc'
        else if (contractStartDateSort == 'asc')
            newSort = 'desc'
        else
            newSort = 'none'
        thunkAPI.dispatch(usersListActions.setContractStartDateSort(newSort))
        thunkAPI.dispatch(usersListActions.fetchUsers())
    },
)

const switchSortContractEndDate = createAsyncThunk(
    'switchSortContractEndDate',
    async (_, thunkAPI: any) => {
        const {usersList} = thunkAPI.getState()
        const {contractEndDateSort} = usersList
        let newSort
        if (contractEndDateSort == 'none')
            newSort = 'asc'
        else if (contractEndDateSort == 'asc')
            newSort = 'desc'
        else
            newSort = 'none'
        thunkAPI.dispatch(usersListActions.setContractEndDateSort(newSort))
        thunkAPI.dispatch(usersListActions.fetchUsers())
    },
)

const initialState = {
    items: [],
    page: 1,
    pageCount: 1,
    limit: 20,
    searchKeyword: '',
    contractStartDateSort: 'none' as 'none' | 'asc' | 'desc',
    contractEndDateSort: 'none' as 'none' | 'asc' | 'desc',
    corporateNameFilter: '',
    storeNameFilter: '',
    registrationRouteFilter: '',
    planTypesFilter: '',
    isActiveFilter: '',
}

const usersListSlice = createSlice({
    name: 'usersList',
    initialState,
    reducers: {
        setItems: (state, action) => {
            state.items = action.payload
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setPageCount: (state, action) => {
            state.pageCount = action.payload
        },
        setSearchKeyword: (state, action) => {
            state.searchKeyword = action.payload
        },
        setContractStartDateSort: (state, action) => {
            state.contractStartDateSort = action.payload
        },
        setContractEndDateSort: (state, action) => {
            state.contractEndDateSort = action.payload
        },
        setCorporateNameFilter: (state, action) => {
            state.corporateNameFilter = action.payload
        },
        setStoreNameFilter: (state, action) => {
            state.storeNameFilter = action.payload
        },
        setRegistrationRouteFilter: (state, action) => {
            state.registrationRouteFilter = action.payload
        },
        setPlanTypesFilter: (state, action) => {
            state.planTypesFilter = action.payload
        },
        setIsActiveFilter: (state, action) => {
            state.isActiveFilter = action.payload
        },
        setInitState: (state) => {
            return initialState
        }
    },
})

const usersListActions = {
    ...usersListSlice.actions,
    fetchUsers,
    setPageAndFetch,
    doSearchKeywordAndFetch,
    switchSortContractStartDate,
    switchSortContractEndDate,
}
export {usersListActions}
export default usersListSlice.reducer