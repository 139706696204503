import * as Sentry from "@sentry/react";
import cookie from "cookie";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Provider } from 'react-redux'

import { OpenAPI } from "./api";
import store from "./store"
import ProtectedPage from "./components/ProtectedPage";
import Home from "./components/Home";
import Login from "./components/Login";
import UserCreate from './components/UserCreate'
import UserEdit from "./components/UserEdit"
import UserView from './components/UserView'

OpenAPI.interceptors.request.use((request) => {
  const { csrftoken } = cookie.parse(document.cookie);
  if (request.headers && csrftoken) {
    request.headers["X-CSRFTOKEN"] = csrftoken;
  }
  return request;
});

const App = () => (
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <Provider store={store}>
      <HashRouter>
        <Routes>
          <Route path="login" element={<Login />} />
        </Routes>
        <Routes>
          <Route path="" element={<ProtectedPage><Home /></ProtectedPage>} />
        </Routes>
        <Routes>
          <Route path="user-create" element={<ProtectedPage><UserCreate /></ProtectedPage>} />
        </Routes>
        <Routes>
          <Route path="user-edit/:id" element={<ProtectedPage><UserEdit /></ProtectedPage>} />
        </Routes>
        <Routes>
          <Route path="user-view/:id" element={<ProtectedPage><UserView /></ProtectedPage>} />
        </Routes>
      </HashRouter>
    </Provider>
  </Sentry.ErrorBoundary>
);

export default App;
