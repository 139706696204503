import { useEffect } from "react"
import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import {ToastContainer} from 'react-toastify'
import {toastActions} from '../reducers/toast'

function mapStateToProps(state: any) {
  return {}
}

const Toast = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  useEffect(() => {
    dispatch(toastActions.showFlashMsgs())
  }, []);

  return (
    <ToastContainer hideProgressBar/>
  );
};

export default connect(mapStateToProps)(Toast)
