import { useEffect } from "react"
import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import StaffNavBar from './StaffNavBar'
import UserEditForm from "./UserEditForm"
import Toast from './Toast'
import {editingUserActions} from '../reducers/editingUser'

function mapStateToProps(state: any) {
  return { ...state.currentUser }
}

const UserCreate = (props: any) => {
  const {is_staff} = props
  if (!is_staff) return null
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  useEffect(() => {
      dispatch(editingUserActions.setInitState())
  }, []);

  return (
    <>
      <Toast />
      <StaffNavBar />
      <UserEditForm />
    </>
  );
};

export default connect(mapStateToProps)(UserCreate)
