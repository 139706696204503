import { useEffect } from "react"
import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import {useNavigate} from 'react-router-dom'
import { Modal } from "flowbite-react";
import {CloseModalIcon} from './SVGIcons'
import {roomDesignActions} from '../reducers/roomDesign'
import LifestyleImage from '../../assets/images/carousel-1.svg'
import pho_industrial from '../../assets/images/pho_industrial.webp'
import pho_modern from '../../assets/images/pho_modern.webp'
import {selectBeforeImageData} from '../selectors'

function mapStateToProps(state: any) {
  const selectedBeforeImageData = selectBeforeImageData(state)
  return { ...state.roomDesign, selectedBeforeImageData }
}

const SelectLifestyle = (props: any) => {
  const {
    isLifestylePopupShow,
    selectedBeforeImageData,
  } = props
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()
  if (!selectedBeforeImageData) return null
  const {lifestyle} = selectedBeforeImageData
  const closePopup = () => {
    dispatch(roomDesignActions.setIsLifestylePopupShow(false))
  }
  const setLifestyle = (value: any) => {
    dispatch(roomDesignActions.setLifestyle(value))
    dispatch(roomDesignActions.setIsLifestylePopupShow(false))
  }

  return (
    <>
      <Modal show={isLifestylePopupShow} onClose={closePopup}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full p-4 text-center sm:items-center sm:p-0">
            <div className="absolute p-4 w-full max-w-2xl popup-select-lifestyle">
              <div className="relative bg-white rounded-lg shadow">
                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    生活スタイル
                  </h3>
                  <button type="button" onClick={closePopup}
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center">
                    <CloseModalIcon />
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="p-4 md:p-5 space-y-4">

                  <div className="grid gap-3 grid-cols-3">
                    <div className="relative col-1">
                      <label onClick={() => setLifestyle("industrial")}
                        className={lifestyle == "industrial"
                          ? "border-1 border-yellow-300 bg-yellow-300 flex cursor-pointer select-none border overflow-hidden h-full group"
                          : "bg-white border-1 border-gray-200 flex cursor-pointer select-none border overflow-hidden h-full group"
                        }>
                        <span className="bg-[#ff4600cc] absolute top-1/2 -mt-6 inset-x-0 p-2 text-center font-bold text-[#2d2d2d] text-white">インダストリアル</span>
                        <figure>
                          <img src={pho_industrial} alt="インダストリアル" className="w-full h-auto object-cover"/>
                        </figure>
                      </label>
                    </div>

                    <div className="relative col-1">
                      <label onClick={() => setLifestyle("modern")}
                        className={lifestyle == "modern"
                          ? "border-1 border-yellow-300 bg-yellow-300 flex cursor-pointer select-none border overflow-hidden h-full group"
                          : "bg-white border-1 border-gray-200 flex cursor-pointer select-none border overflow-hidden h-full group"
                        }>
                        <span className="bg-[#ff4600cc] absolute top-1/2 -mt-6 inset-x-0 p-2 text-center font-bold text-[#2d2d2d] text-white">モダン</span>
                        <figure>
                          <img src={pho_modern} alt="モダン" className="w-full h-auto object-cover"/>
                        </figure>
                      </label>
                    </div>

                    <div className="relative col-1">
                      <label
                        className="bg-white border-1 border-gray-200 peer-checked:border-yellow-300 peer-checked:bg-yellow-300 flex cursor-pointer select-none border overflow-hidden h-full group">
                        <span className="bg-[#ff4600cc] absolute top-1/2 -mt-6 inset-x-0 p-2 text-center font-bold text-[#2d2d2d] text-white">Coming Soon</span>
                        <figure>
                          <img src={LifestyleImage} alt="" className="w-full h-auto object-cover"/>
                        </figure>
                      </label>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(SelectLifestyle)
