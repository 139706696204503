import { useEffect } from "react"
import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import {useNavigate} from 'react-router-dom'
import { Modal } from "flowbite-react";
import {CloseModalIcon, ConfirmGenerateAfterPhotoIcon} from './SVGIcons'
import {roomDesignActions} from '../reducers/roomDesign'
import {selectCanCreateRoomDesign} from '../selectors'

function mapStateToProps(state: any) {
  const canCreateRoomDesign = selectCanCreateRoomDesign(state)
  return { ...state.roomDesign, ...state.currentUser, canCreateRoomDesign }
}

const CreateRoomDesignConfirm = (props: any) => {
  const {
    isConfirmPopupShow,
    canCreateRoomDesign,
    creditConsumed, 
    creditLimit,
  } = props
  const consumedRatio = creditConsumed / creditLimit * 100 + '%'
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()
  const closePopup = () => {
    dispatch(roomDesignActions.setIsConfirmPopupShow(false))
  }

  const createRoomDesign = () => {
    if (!canCreateRoomDesign) return

    dispatch(roomDesignActions.setIsConfirmPopupShow(false))
    dispatch(roomDesignActions.createRoomDesign(navigate))
  }

  return (
    <>
      <Modal show={isConfirmPopupShow} onClose={closePopup} size="md">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <button type="button" onClick={closePopup}
                className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center">
                <CloseModalIcon />
                <span className="sr-only">閉じる</span>
              </button>
              <div className="p-4 md:p-5">
                <ConfirmGenerateAfterPhotoIcon />
                <h3 className="text-base font-semibold leading-6 text-gray-900">画像生成に1回分のクレジットを使用します。よろしいですか？<br/><br/></h3>
                <div className="flex justify-between mb-1 text-gray-500">
                  <span className="text-base font-normal">生成回数</span>
                  <span className="text-sm font-semibold text-gray-900">残り{creditLimit - creditConsumed}回/{creditLimit}回</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div className="bg-orange-500 h-2.5 rounded-full" style={{width: consumedRatio}}></div>
                </div>
                <div className="flex flex-row-reverse items-center mt-6 space-x-4">
                  <button type="button" onClick={createRoomDesign}
                    className="sm:ml-3 w-28 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">画像生成</button>
                  <button type="button" onClick={closePopup}
                    className="sm:ml-3 w-28 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100">キャンセル</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(CreateRoomDesignConfirm)
