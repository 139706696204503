import {ThunkDispatch} from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import {connect, useDispatch } from "react-redux"
import { useEffect } from "react";
import { Dropdown } from "flowbite-react";
import {currentUserActions} from '../reducers/currentUser'
import {HamburgerIcon, LogoutIcon} from './SVGIcons'
import Logo from '../../assets/images/logo.svg'

function mapStateToProps(state: any) {
  return { ...state.currentUser }
}

const StaffNavBar = (props: any) => {
  const {email} = props
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const goToMamageUser = () => {
    navigate("/")
  }
  const signOutUser = () => {
    dispatch(currentUserActions.signOutUser(navigate))
  }
  
  return (
    <>
      <header
        className="sticky top-0 z-40 w-full backdrop-blur flex-none transition-colors duration-500 lg:z-50 lg:border-b lg:border-slate-900/10 bg-white supports-backdrop-blur:bg-white/95">
        <div
          className="max-w-8xl mx-autopy-4 border-b border-slate-900/10 lg:px-8 lg:border-0 mx-4 lg:mx-0 flex items-center h-14">
          <div className="flex gap-2 items-center">
            <h1>
              <a href="#"><img src={Logo} alt="らくらく デコルーム" className="max-h-10"/></a>
            </h1>
          </div>

          <div className="flex ml-auto absolute top-2 right-[2rem]">
            <nav>
              <div className="flex items-center mx-auto">
                <Dropdown label="" renderTrigger={() => (
                  <button type="button"
                    className="inline-flex items-center p-2 h-10 justify-center text-sm text-gray-500 rounded-lg hover:bg-gray-100"
                  >
                    <span className="mr-2">{email}</span><HamburgerIcon />
                  </button>
                )}>
                <div
                  className="absolute right-[-1.9rem] top-0 min-w-64 p-4 border border-gray-100 rounded-b-lg bg-gray-50 flex flex-col drop-shadow-lg">
                  <a href="#" onClick={signOutUser}
                    className="flex py-2 px-3 rounded text-gray-900 hover:bg-gray-100 mt-auto gap-1 items-center">
                    <LogoutIcon />
                    ログアウト</a>
                </div>
                </Dropdown>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  )
};

export default connect(mapStateToProps)(StaffNavBar)