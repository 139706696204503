import { useEffect } from "react"
import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import {useNavigate} from 'react-router-dom'
import { Modal } from "flowbite-react";
import {CloseModalIcon, ConfirmGenerateAfterPhotoIcon} from './SVGIcons'
import {roomDesignActions} from '../reducers/roomDesign'

function mapStateToProps(state: any) {
  return { ...state.roomDesign }
}

const RoomDesignPhotoTooBigPopup = (props: any) => {
  const {
    isPhotoTooBigPopupShow,
  } = props
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()
  const closePopup = () => {
    dispatch(roomDesignActions.setIsPhotoTooBigPopupShow(false))
  }

  return (
    <>
      <Modal show={isPhotoTooBigPopupShow} onClose={closePopup} size="md">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <button type="button" onClick={closePopup}
                className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center">
                <CloseModalIcon />
                <span className="sr-only">閉じる</span>
              </button>
              <div className="p-4 md:p-5">
                <h3 className="text-base font-semibold leading-6 text-gray-900">5M以上のサイズの画像はアップロードできません。</h3>
                <div className="flex flex-row-reverse items-center mt-6 space-x-4">
                  <button type="button" onClick={closePopup}
                    className="sm:ml-3 w-28 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">OK</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(RoomDesignPhotoTooBigPopup)
