import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import StaffNavBar from './StaffNavBar'
import UserList from './UserList'
import UserDeleteConfirm from './UserDeleteConfirm'
import SiteNotiEdit from './SiteNotiEdit'
import Toast from './Toast'

const UserManage = () => {
  const navigate = useNavigate()
  return (
    <>
      <Toast />
      <StaffNavBar />
      <UserList />
      <UserDeleteConfirm />
      <SiteNotiEdit />
    </>
  );
};

export default UserManage;
