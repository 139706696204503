import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import RoomDesignEditFormEmpty from './RoomDesignEditFormEmpty'
import RoomDesignEditFormSome from './RoomDesignEditFormSome'

function mapStateToProps(state: any) {
  return { ...state.roomDesign }
}

const RoomDesignEditForm = (props: any) => {
  const {
    beforeImages,
  } = props
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()

  return (
    <>
      {(beforeImages.length == 0) && (
        <RoomDesignEditFormEmpty />
      )}
      {(beforeImages.length != 0) && (
        <RoomDesignEditFormSome />
      )}
    </>
  );
};

export default connect(mapStateToProps)(RoomDesignEditForm)