import { useEffect } from "react"
import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import {useNavigate} from 'react-router-dom'
import { Modal } from "flowbite-react";
import {WarningIcon} from './SVGIcons'
import {deleteUserActions} from '../reducers/deleteUser'

function mapStateToProps(state: any) {
  return { ...state.deleteUser }
}

const UserDeleteConfirm = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()
  const {id, email, isPopupShow} = props
  const closePopup = () => {
    dispatch(deleteUserActions.setInitState())
  }
  const doDeleteUser = () => {
    dispatch(deleteUserActions.doDeleteUser(id))
  }

  return (
    <>
      <Modal show={isPopupShow} onClose={closePopup}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <WarningIcon />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">ユーザー削除</h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">アカウント[<strong>{email}</strong>]を削除してよろしいですか？</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border-t">
                <button type="button" onClick={doDeleteUser} className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">削除</button>
                <button type="button" onClick={closePopup} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">キャンセル</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(UserDeleteConfirm)
