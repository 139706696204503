import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {UsersService} from '../api'
import {toastActions} from './toast'
import {usersListActions} from './usersList'

const doDeleteUser = createAsyncThunk(
    'doDeleteUser',
    async (id: number, thunkAPI: any) => {
        const {email} = thunkAPI.getState().deleteUser
        const res = await UsersService.usersDestroy({id})
        thunkAPI.dispatch(deleteUserActions.setInitState())
        if (!res.success) {
            thunkAPI.dispatch(toastActions.toastError(res.message))
            return
        }

        thunkAPI.dispatch(toastActions.toastSuccess(`ユーザー（ ${email} ）が削除されました。`))//User ${email} is deleted.
        thunkAPI.dispatch(usersListActions.fetchUsers())
    }
)

const initialState = {
    id: undefined,
    email: undefined,
    isPopupShow: false,
}

const deleteUserSlice = createSlice({
    name: 'deleteUser',
    initialState,
    reducers: {
        setId: (state, action) => {
            state.id = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setIsPopupShow: (state, action) => {
            state.isPopupShow = action.payload
        },
        setdeleteUser: (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        },
        setInitState: (state) => {
            return initialState
        },
    },
})

const deleteUserActions = {
    ...deleteUserSlice.actions,
    doDeleteUser,
}
export {deleteUserActions}
export default deleteUserSlice.reducer