import { useRef } from 'react';
import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { FileUploader } from "react-drag-drop-files"
import {roomDesignActions} from '../reducers/roomDesign'
import {selectCanChangePageSetting, selectCanReloadRoomDesign, selectBeforeImageData} from '../selectors'
import SiteNoti from './SiteNoti'
import ExamplePhotoNG from './ExamplePhotoNG'
import RoomDesignEditRoomTypeLifestyle from './RoomDesignEditRoomTypeLifestyle'
import RoomDesignEditActionButtons from './RoomDesignEditActionButtons'
import {ReloadRoomDesignIcon, DelPhotoIcon, AddMorePhotoIcon, 
  AfterImageDownloadIcon, AfterImageBackwardIcon, AfterImageForwardIcon} from './SVGIcons'

function mapStateToProps(state: any) {
  const canChangePageSetting = selectCanChangePageSetting(state)
  const canReloadRoomDesign = selectCanReloadRoomDesign(state)
  const selectedBeforeImageData = selectBeforeImageData(state)
  return { ...state.roomDesign, canChangePageSetting, canReloadRoomDesign, selectedBeforeImageData }
}

const fileTypes = ["JPG", "PNG", "WEBP"];

const RoomDesignEditFormSome = (props: any) => {
  let {
    id,
    isLoading,
    selectedBeforeImage,
    selectedRegenImage,
    selectedAfterImage,
    beforeImages,
    afterImages,
    maxExecTimePerImage,
    runTime,
    isGeneratedFail,
    canChangePageSetting,
    canReloadRoomDesign,
    selectedBeforeImageData,
  } = props
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()
  const selectedAfterImages = afterImages && selectedBeforeImageData 
    ? afterImages.filter((image: any) => image.before_image_id == selectedBeforeImageData.id) : []
  const findAfterImageData = selectedAfterImages.find((image: any) => image.id == selectedAfterImage)
  const selectedAfterImageData = findAfterImageData ? findAfterImageData : selectedAfterImages[0]
  const selectedAfterImageIndex = selectedAfterImages.findIndex((image: any) => image.id == selectedAfterImageData.id)

  const maxExecTime = maxExecTimePerImage * beforeImages.length
  const processPercent = runTime < maxExecTime*0.9 ? runTime / maxExecTime : 0.9
  const displayPercent = Math.floor(processPercent * 100) + "%"
  const strokeDashoffset = 100 - 100*processPercent

  const inputFile = useRef<HTMLInputElement | null>(null);
  const onButtonAddImage = () => {
    if (!inputFile || !inputFile.current) return

    inputFile.current.click();
  }
  const addBeforeImages = (event: any) => {
    if (!canChangePageSetting) return

    dispatch(roomDesignActions.addBeforeImages(event.target.files))
  }
  const addDropImages = (event: any) => {
    if (!canChangePageSetting) return

    dispatch(roomDesignActions.addBeforeImages(event))
  }
  const removeBeforeImage = (stubId: number) => {
    if (!canChangePageSetting) return
    
    dispatch(roomDesignActions.removeBeforeImage(stubId))
  }
  const setSelectedBeforeImage = (stubId: number) => {
    dispatch(roomDesignActions.setSelectedBeforeImage(stubId))
  }
  const setSelectedAfterImage = (id: number) => {
    dispatch(roomDesignActions.setSelectedAfterImage(id))
  }
  const selectPrevAfterImage = () => {
    if (selectedAfterImages[selectedAfterImageIndex-1])
      dispatch(roomDesignActions.setSelectedAfterImage(selectedAfterImages[selectedAfterImageIndex-1].id))
  }
  const selectNextAfterImage = () => {
    if (selectedAfterImages[selectedAfterImageIndex+1])
      dispatch(roomDesignActions.setSelectedAfterImage(selectedAfterImages[selectedAfterImageIndex+1].id))
  }
  const reloadPage = () => {
    if(!canReloadRoomDesign) return

    dispatch(roomDesignActions.setInitState())
  }

  return (
    <>
      <div className="max-w-8xl w-full mx-auto py-3 px-4 sm:px-6 md:px-8 text-[#2d2d2d]">
        <SiteNoti />

        <div className="grid grid-cols-9 gap-2">
          <div className="col-1 bg-gray-200 overflow-y-auto rounded min-h-[30.28rem] flex flex-col p-2">
            <button type="button" onClick={reloadPage}
              className="text-gray-900 bg-gray-50 flex p-2 rounded-md border border-yellow-50 mb-4 text-sm text-yellow-700 items-center justify-center">
              <ReloadRoomDesignIcon />やり直す
            </button>
            <ul className="space-y-2">
              {beforeImages.map((beforeImage: any) => (
                <li key={beforeImage.stubId} onClick={e => setSelectedBeforeImage(beforeImage.stubId)}
                  className={beforeImage.stubId == selectedBeforeImageData.stubId 
                    ? "flex items-center justify-center w-full bg-gray-300 h-24 w-28 overflow-hidden mx-auto rounded-sm cursor-pointer relative ring-offset-2 ring-2 ring-blue-500"
                    : "flex items-center justify-center w-full bg-gray-300 h-24 w-28 overflow-hidden mx-auto rounded-sm cursor-pointer relative"}>
                  {canChangePageSetting && (
                    <button type="button" onClick={e => removeBeforeImage(beforeImage.stubId)} className="absolute top-1 right-1 opacity-50 hover:opacity-70">
                      <DelPhotoIcon />
                    </button>
                  )}
                  <img src={beforeImage.uri} className="block w-full" alt=""/>
                </li>
              ))}

              {canChangePageSetting && (
                <li className="text-center">
                  <label>
                    <button type="button" onClick={onButtonAddImage} className="text-blue-600 hover:text-blue-600 rounded-full hover:bg-gray-100">
                      <AddMorePhotoIcon />
                    </button>
                    <input type="file" ref={inputFile} multiple accept="image/png, image/jpeg, image/webp" onChange={addBeforeImages} className="hidden" />
                  </label>
                </li>
              )}
            </ul>
          </div>

          <div
            className="grid grid-cols-2 col-span-8 border border-gray-300 bg-white overflow-y-auto rounded">
            <div className="flex flex-col w-full col-1 flex-nowrap p-3 bg-white">
              <RoomDesignEditRoomTypeLifestyle />
              <div
                className="flex flex-col items-center justify-center h-full rounded">
                {selectedBeforeImageData && (
                  <FileUploader multiple handleChange={addDropImages} dropMessageStyle={{display: 'none'}} classes="h-full" types={fileTypes} children={(
                    <img src={selectedBeforeImageData.uri} className="block w-full" alt=""/>
                  )}></FileUploader>
                )}
              </div>
            </div>
            
            {!id && !isLoading && !isGeneratedFail && (
              <ExamplePhotoNG />
            )}
            {!id && !isLoading && isGeneratedFail && (
              <div className="p-3 col-1 border-s border-gray-300 flex">
                <div className="relative m-auto text-red-600 text-center text-lg">
                  画像生成に失敗しました。<br />
                  再度画像生成を行ってください。<br />
                  ※クレジットは消費されておりません。
                </div>
              </div>
            )}
            {id && (!isLoading || (selectedRegenImage && selectedBeforeImageData.stubId != selectedRegenImage)) && selectedAfterImageData && afterImages.length > 0 && (
              <div className="p-3 col-1 border-s border-gray-300 flex">
                <div className="relative w-full" data-carousel="static">
                  <div className="flex mb-2 mt-10 relative z-30 space-x-1">
                    {selectedAfterImages.map((image: any, index: number) => (
                      <button key={image.id} onClick={() => setSelectedAfterImage(image.id)} className={selectedAfterImageData && selectedAfterImageData.id == image.id
                        ? "min-w-6 min-h-6 bg-blue-600 text-white hover:text-gray-400 rounded"
                        : "min-w-6 min-h-6 bg-gray-800 text-white hover:text-gray-400 rounded"
                      }>{index+1}</button>
                    ))}
                  </div>
                  <div className="relative">
                    {selectedAfterImageData && (
                      <div className="duration-100 group">
                        <a href={selectedAfterImageData.serverURI} download="" className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-30 rounded-full p-3 bg-white/95 hover:bg-white/90 hidden group-hover:block">
                          <AfterImageDownloadIcon />
                        </a>
                        <span className="absolute group-hover:inset-0 group-hover:bg-gray-500/60 z-10"></span>
                          <img src={selectedAfterImageData.serverURI} className="block w-full" alt=""/>
                      </div>
                    )}
                  </div>
                  {selectedAfterImages[selectedAfterImageIndex-1] && (
                    <button onClick={selectPrevAfterImage}
                      className="absolute top-1/2 start-0 z-30 flex items-center justify-center px-4 cursor-pointer group focus:outline-none">
                      <span
                        className="inline-flex items-center justify-center w-10 h-10 rounded-lg bg-white/30/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none border border-gray-400">
                        <AfterImageBackwardIcon />
                        <span className="sr-only">前へ</span>
                      </span>
                    </button>
                  )}
                  {selectedAfterImages[selectedAfterImageIndex+1] && (
                    <button onClick={selectNextAfterImage}
                      className="absolute top-1/2 end-0 z-30 flex items-center justify-center px-4 cursor-pointer group focus:outline-none">
                      <span
                        className="inline-flex items-center justify-center w-10 h-10 rounded-lg bg-white/30/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none border border-gray-400">
                        <AfterImageForwardIcon />
                        <span className="sr-only">次へ</span>
                      </span>
                    </button>
                  )}
                </div>
              </div>
            )}
            {isLoading && (!selectedRegenImage || selectedBeforeImageData.stubId == selectedRegenImage) && (
              <div className="p-3 col-1 border-s border-gray-300 flex">
                <div className="relative size-40 m-auto">
                  <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200"
                      strokeWidth="2"></circle>
                    <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-blue-600"
                      strokeWidth="2" strokeDasharray="100" strokeDashoffset={strokeDashoffset} strokeLinecap="round"></circle>
                  </svg>
                  <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
                    <span className="text-center text-2xl font-bold text-blue-600">{displayPercent}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <RoomDesignEditActionButtons />
      </div>
    </>
  );
};

export default connect(mapStateToProps)(RoomDesignEditFormSome)