import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const toastSuccess = createAsyncThunk(
    'toastSuccess',
    async (msg: string, thunkAPI: any) => {
        toast.success(msg)
    }
)
const toastError = createAsyncThunk(
    'toastError',
    async (msg: string, thunkAPI: any) => {
        toast.error(msg)
    }
)
const flashSuccess = createAsyncThunk(
    'flashSuccess',
    async (msg: string, thunkAPI: any) => {
        thunkAPI.dispatch(toastActions.pushMsg({type: 'success', msg}))
    }
)
const flashError = createAsyncThunk(
    'flashError',
    async (msg: string, thunkAPI: any) => {
        thunkAPI.dispatch(toastActions.pushMsg({type: 'error', msg}))
    }
)

const showFlashMsgs = createAsyncThunk(
    'showFlashMsgs',
    async (_, thunkAPI: any) => {
        const msgs = thunkAPI.getState().toast
        msgs.forEach((msg: any) => {
            if (msg['type'] == 'success') {
                toast.success(msg['msg'])
                return
            }
            if (msg['type'] == 'error') {
                toast.error(msg['msg'])
                return
            }
        })
        thunkAPI.dispatch(toastActions.setInitState())
    }
)

const initialState = [] as any[]

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        pushMsg: (state, action) => {
            const{type, msg} = action.payload
            state.push({type, msg})
        },
        setInitState: (state) => {
            return initialState
        },
    },
})

const toastActions = {
    ...toastSlice.actions,
    toastSuccess,
    toastError,
    flashSuccess,
    flashError,
    showFlashMsgs,
}
export {toastActions}
export default toastSlice.reducer