import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {SiteNotiIcon} from './SVGIcons'

function mapStateToProps(state: any) {
  return {...state.siteNoti}
}

const SiteNoti = (props: any) => {
  const {
    noti,
  } = props
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()
  if (!noti) return null

  return (
    <div className="flex bg-yellow-50 p-3 mb-4 rounded-md border border-yellow-500">
      <div>
        <SiteNotiIcon />
      </div>
      <p className="ml-3 text-yellow-700 text-sm">
        {noti.split('\n').map((notiRow: string, index: number) => (
          <span key={index}>{notiRow}<br /></span>
        ))}
      </p>
    </div>
  );
};

export default connect(mapStateToProps)(SiteNoti)