import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {toastActions} from './toast'
import {UserFilterKeywordsService} from '../api'
import {isStringArraysEqual} from '../libs'
import {usersListActions} from './usersList'
import {PlanType, IsActive} from '../constants'

interface CheckItem {
    label: string,
    value: string,
    isChecked: boolean,
}

const getFilters = createAsyncThunk(
    'getFilters',
    async (_, thunkAPI: any) => {
        const res = await UserFilterKeywordsService.userFilterKeywords()
        let {corporate_names, store_names, registration_routes} = res
        const {corporateNames, storeNames, registrationRoutes} = thunkAPI.getState().filterUser
        if (isSameCheckItemsVsNames(corporateNames, corporate_names)
            && isSameCheckItemsVsNames(storeNames, store_names)
            && isSameCheckItemsVsNames(registrationRoutes, registration_routes))
            return

        const newCorporateNames = getCheckItemsFromNames(corporate_names)
        thunkAPI.dispatch(filterUserActions.setCorporateNames(newCorporateNames))
        const newStoreNames = getCheckItemsFromNames(store_names)
        thunkAPI.dispatch(filterUserActions.setStoreNames(newStoreNames))
        const newRegistrationRoutes = getCheckItemsFromNames(registration_routes)
        thunkAPI.dispatch(filterUserActions.setRegistrationRoutes(newRegistrationRoutes))
        const newPlanTypes = getCheckItemsFromOptions(PlanType)
        thunkAPI.dispatch(filterUserActions.setPlanTypes(newPlanTypes))
        const newIsActives = getCheckItemsFromOptions(IsActive)
        thunkAPI.dispatch(filterUserActions.setIsActives(newIsActives))
    }
)

function isSameCheckItemsVsNames(checkItems: CheckItem[], names: string[]) {
    const nnnames = names.filter(name => name)
    const existingCheckItemsNames = checkItems.map(checkItem => checkItem['label'])
    return isStringArraysEqual(nnnames, existingCheckItemsNames)
}

function getCheckItemsFromNames(names: string[]) {
    return names.filter(name => name).map((name: string) => {return {label: name, value: name,isChecked: false}})
}

function getCheckItemsFromOptions(options: any) {
    let checkItems = [] as CheckItem[]
    Object.keys(options).forEach(key => {
        checkItems.push({
            label: key,
            value: options[key],
            isChecked: false
        })
    })
    return checkItems
}

const applyFilter = createAsyncThunk(
    'applyFilter',
    async (_, thunkAPI: any) => {
        const {
            corporateNames, 
            storeNames, 
            registrationRoutes, 
            planTypes,
            isActives,
        } = thunkAPI.getState().filterUser

        thunkAPI.dispatch(usersListActions.setCorporateNameFilter(getFilterStr(corporateNames)))
        thunkAPI.dispatch(usersListActions.setStoreNameFilter(getFilterStr(storeNames)))
        thunkAPI.dispatch(usersListActions.setRegistrationRouteFilter(getFilterStr(registrationRoutes)))
        thunkAPI.dispatch(usersListActions.setPlanTypesFilter(getFilterStr(planTypes)))
        thunkAPI.dispatch(usersListActions.setIsActiveFilter(getFilterStr(isActives)))
    }
)

function getFilterStr(checkItems: CheckItem[]) {
    return checkItems.filter(item => item.isChecked).map((checkItem: CheckItem) => checkItem.value).join(',')
}


function setCheckedForList(checkItems: CheckItem[], newCheckItems: CheckItem[]) {
    const checkedValues = newCheckItems.map(item => item.value)
    checkItems.forEach(checkItem => {
        if (!checkedValues.includes(checkItem.value)) {
            checkItem.isChecked = false
            return
        }

        checkItem.isChecked = true
    })
}

const initialState = {
    corporateNames: [] as CheckItem[],
    storeNames: [] as CheckItem[],
    registrationRoutes: [] as CheckItem[],
    planTypes: [] as CheckItem[],
    isActives: [] as CheckItem[],
}

const filterUserSlice = createSlice({
    name: 'filterUser',
    initialState,
    reducers: {
        setCorporateNames: (state, action) => {
            state.corporateNames = action.payload
        },
        switchCorporateNameChecked: (state, action) => {
            setCheckedForList(state.corporateNames, action.payload)
        },
        setStoreNames: (state, action) => {
            state.storeNames = action.payload
        },
        switchStoreNameChecked: (state, action) => {
            setCheckedForList(state.storeNames, action.payload)
        },
        setRegistrationRoutes: (state, action) => {
            state.registrationRoutes = action.payload
        },
        switchRegistrationRouteChecked: (state, action) => {
            setCheckedForList(state.registrationRoutes, action.payload)
        },
        setPlanTypes: (state, action) => {
            state.planTypes = action.payload
        },
        switchPlanTypeChecked: (state, action) => {
            setCheckedForList(state.planTypes, action.payload)
        },
        setIsActives: (state, action) => {
            state.isActives = action.payload
        },
        switchIsActiveChecked: (state, action) => {
            setCheckedForList(state.isActives, action.payload)
        },
        setInitState: (state) => {
            return initialState
        },
    },
})

const filterUserActions = {
    ...filterUserSlice.actions,
    getFilters,
    applyFilter,
}
export {filterUserActions}
export default filterUserSlice.reducer