export function selectCanCreateRoomDesign(state: any) {
    const {currentUser, roomDesign} = state
    const {creditConsumed, creditLimit} = currentUser
    if (creditConsumed >= creditLimit) return false

    const {id, isLoading, beforeImages} = roomDesign
    const nonOKImages = beforeImages.filter((image: any) => !image.roomType || !image.lifestyle)
    if (id || isLoading || beforeImages.length == 0 || nonOKImages.length > 0) return false

    return true
}

export function selectCanRegenAfterImages(state: any) {
    const {roomDesign} = state
    const {id, isLoading, maxRegenCount} = roomDesign
    return id && !isLoading && maxRegenCount > 0
}

export function selectCanChangePageSetting(state: any) {
    const {roomDesign} = state
    const {id, isLoading} = roomDesign
    if (id || isLoading) return false

    return true
}

export function selectCanReloadRoomDesign(state: any) {
    const {roomDesign} = state
    const {isLoading} = roomDesign
    return !isLoading
}

export function selectCanConfirmReloadRoomDesign(state: any) {
    const {roomDesign} = state
    const {id, isLoading, isGeneratedFail} = roomDesign
    return !isLoading && (id || isGeneratedFail)
}

export function selectBeforeImageData(state: any) {
    const {roomDesign} = state
    const {selectedBeforeImage, beforeImages} = roomDesign
    const existBeforeImageData = beforeImages.find((image: any) => image.stubId == selectedBeforeImage)
    const selectedBeforeImageData = existBeforeImageData ? existBeforeImageData : beforeImages[0]
    return selectedBeforeImageData
}