import { useNavigate } from "react-router-dom";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import {ClearFilterIcon} from './SVGIcons'
import {usersListActions} from '../reducers/usersList'
import {siteNotiActions} from '../reducers/siteNoti'
import {filterUserActions} from '../reducers/filterUser'
import { MultiSelect } from 'primereact/multiselect';

function mapStateToProps(state: any) {
  return { ...state.usersList, ...state.filterUser }
}

const UserListActionBar = (props: any) => {
  const {
    searchKeyword,
    corporateNames,
    storeNames,
    registrationRoutes,
    planTypes,
    isActives,
  } = props
  const navigate = useNavigate()
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const selectedCorporateNames = corporateNames.filter((item: any) => item.isChecked).map((item: any) => item.value)
  const selectedStoreNames = storeNames.filter((item: any) => item.isChecked).map((item: any) => item.value)
  const selectedRegistrationRoutes = registrationRoutes.filter((item: any) => item.isChecked).map((item: any) => item.value)
  const selectedPlanTypes = planTypes.filter((item: any) => item.isChecked).map((item: any) => item.value)
  const selectedIsActives = isActives.filter((item: any) => item.isChecked).map((item: any) => item.value)

  const switchCorporateNameChecked = (newValue: any[]) => {
    const newCheckItems = corporateNames.filter((item: any) => newValue.includes(item.value))
    dispatch(filterUserActions.switchCorporateNameChecked(newCheckItems))
  }
  const switchStoreNameChecked = (newValue: any[]) => {
    const newCheckItems = storeNames.filter((item: any) => newValue.includes(item.value))
    dispatch(filterUserActions.switchStoreNameChecked(newCheckItems))
  }
  const switchRegistrationRouteChecked = (newValue: any[]) => {
    const newCheckItems = registrationRoutes.filter((item: any) => newValue.includes(item.value))
    dispatch(filterUserActions.switchRegistrationRouteChecked(newCheckItems))
  }
  const switchPlanTypeChecked = (newValue: any[]) => {
    const newCheckItems = planTypes.filter((item: any) => newValue.includes(item.value))
    dispatch(filterUserActions.switchPlanTypeChecked(newCheckItems))
  }
  const switchIsActiveChecked = (newValue: any[]) => {
    const newCheckItems = isActives.filter((item: any) => newValue.includes(item.value))
    dispatch(filterUserActions.switchIsActiveChecked(newCheckItems))
  }
    const doSearch = () => {
    dispatch(filterUserActions.applyFilter())
    dispatch(usersListActions.doSearchKeywordAndFetch())
  }
  const clearSearch = () => {
    dispatch(usersListActions.setSearchKeyword(''))
    dispatch(filterUserActions.setInitState())
    dispatch(filterUserActions.getFilters())
    dispatch(filterUserActions.applyFilter())
    dispatch(usersListActions.doSearchKeywordAndFetch())
  }

  const goToCreateUser = () => {
    navigate("/user-create")
  }

  const showSiteNotiEdit = () => {
    dispatch(siteNotiActions.setIsPopupShow(true))
    dispatch(siteNotiActions.getSiteNoti())
  }

  return (
    <>
      <div className="flex sticky top-14 bg-white z-30 min-h-12 text-[#2d2d2d] py-2">
        <div className="flex items-center max-w-8xl w-full mx-auto px-4 sm:px-6 md:px-8">
          <form className="w-auto mr-3">
            <div className="flex">
              <div className="grid grid-cols-9 gap-2 w-full">
                <div className="col-span-3 flex items-center gap-2">
                  <div className="flex items-center w-full">
                    <input type="text" value={searchKeyword} onChange={e => dispatch(usersListActions.setSearchKeyword(e.target.value))}
                      className="block w-full p-2 text-gray-900 border border-gray-300 rounded bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 min-w-full"
                      placeholder="法人名、店舗名、メール アドレス" />
                  </div>
                </div>
                <div className="col-span-3 flex items-center gap-2">
                  <label className="text-xs flex-shrink-0 min-w-16 text-right font-bold">法人名</label>
                  <div className="grid ml-auto w-full">
                    <MultiSelect value={selectedCorporateNames} onChange={(e) => switchCorporateNameChecked(e.value)} options={corporateNames} optionLabel="label" 
                      filter placeholder="（全て選択）" className="appearance-none row-start-1 col-start-1 text-gray-900 border border-gray-300 rounded bg-gray-50 text-xs truncate focus:ring-blue-500 focus:border-blue-500 w-[144px] max-w-[144px] h-[2.125rem]" />
                  </div>
                </div>
                <div className="col-span-3 flex items-center gap-2">
                  <label className="text-xs flex-shrink-0 min-w-16 text-right font-bold">店舗名</label>
                  <div className="grid w-full">
                    <MultiSelect value={selectedStoreNames} onChange={(e) => switchStoreNameChecked(e.value)} options={storeNames} optionLabel="label" 
                      filter placeholder="（全て選択）" className="appearance-none row-start-1 col-start-1 text-gray-900 border border-gray-300 rounded bg-gray-50 text-xs truncate focus:ring-blue-500 focus:border-blue-500 w-[144px] max-w-[144px] h-[2.125rem]" />
                  </div>
                </div>
                <div className="col-span-3 flex items-center gap-2">
                  <label className="text-xs flex-shrink-0 min-w-16 text-right font-bold">ステータス</label>
                  <div className="grid w-full">
                    <MultiSelect value={selectedIsActives} onChange={(e) => switchIsActiveChecked(e.value)} options={isActives} optionLabel="label" 
                      filter placeholder="（全て選択）" className="appearance-none row-start-1 col-start-1 text-gray-900 border border-gray-300 rounded bg-gray-50 text-xs truncate focus:ring-blue-500 focus:border-blue-500 min-w-full h-[2.125rem]" />
                  </div>
                </div>
                <div className="col-span-3 flex items-center gap-2">
                  <label className="text-xs flex-shrink-0 min-w-16 text-right font-bold">利用プラン</label>
                  <div className="grid w-full">
                    <MultiSelect value={selectedPlanTypes} onChange={(e) => switchPlanTypeChecked(e.value)} options={planTypes} optionLabel="label" 
                      filter placeholder="（全て選択）" className="appearance-none row-start-1 col-start-1 text-gray-900 border border-gray-300 rounded bg-gray-50 text-xs truncate focus:ring-blue-500 focus:border-blue-500 w-[144px] max-w-[144px] h-[2.125rem]" />
                  </div>
                </div>
                <div className="col-span-3 flex items-center gap-2">
                  <label className="text-xs flex-shrink-0 min-w-16 text-right font-bold">登録経路</label>
                  <div className="grid w-full">
                    <MultiSelect value={selectedRegistrationRoutes} onChange={(e) => switchRegistrationRouteChecked(e.value)} options={registrationRoutes} optionLabel="label" 
                      filter placeholder="（全て選択）" className="appearance-none row-start-1 col-start-1 text-gray-900 border border-gray-300 rounded bg-gray-50 text-xs truncate focus:ring-blue-500 focus:border-blue-500 w-[144px] max-w-[144px] h-[2.125rem]" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 w-[9.2rem] ml-3">
                <button onClick={doSearch}
                className="py-2 px-5 text-xs text-white bg-blue-600 border border-blue-600 rounded-lg hover:bg-blue-700 hover:text-white whitespace-nowrap">検索</button>
                <button onClick={clearSearch}
                  className="py-2 px-3 text-xs font-medium focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:ring-gray-100 flex items-center gap-1">
                  <ClearFilterIcon />
                  条件をクリア</button>
              </div>
            </div>
          </form>

          <div className="ml-auto flex gap-2 self-start">
            <button onClick={showSiteNotiEdit} className="py-2 px-5 text-sm font-medium text-white bg-blue-600 border border-blue-600 hover:bg-blue-700 hover:text-white rounded-md whitespace-nowrap">
              お知らせ編集</button>
            <button onClick={goToCreateUser}
              className="py-2 px-5 text-sm font-medium text-white bg-orange-500 hover:bg-orange-400 rounded-md whitespace-nowrap">新規追加</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(UserListActionBar)
