import { configureStore, } from '@reduxjs/toolkit'
import currentUser from '../reducers/currentUser'
import usersList from '../reducers/usersList'
import editingUser from '../reducers/editingUser'
import deleteUser from '../reducers/deleteUser'
import siteNoti from '../reducers/siteNoti'
import filterUser from '../reducers/filterUser'
import roomDesign from '../reducers/roomDesign'
import toast from '../reducers/toast'

const store = configureStore({
    reducer: {
        currentUser,
        usersList,
        editingUser,
        deleteUser,
        siteNoti,
        filterUser,
        roomDesign,
        toast,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
          serializableCheck: false,
        }),
})

export default store