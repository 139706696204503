import {PlanTypeLimits, PlanTypeRealEstateType} from '../constants/index'
export function formatDateDtAPIPost(date: Date) {
    return `${date.getFullYear()}-${pad2(date.getMonth() + 1)}-${pad2(date.getDate())}`
}

export function formatDateDt(date: Date) {
    return `${date.getFullYear()}/${pad2(date.getMonth() + 1)}/${pad2(date.getDate())}`
}

export function formatDate(dateStr: string) {
    if (!dateStr) return ''
    const date = new Date(dateStr)
    return formatDateDt(date)
}

export function pad2(number: number) {
    return String(number).padStart(2, '0')
}
export function pad5(number: number) {
    return String(number).padStart(5, '0')
}

export function isStringArraysEqual(arr1: string[] | undefined, arr2: string[] | undefined) {
    if (!arr1 || !arr2) return false

    const arr1Str = arr1.join('')
    const arr2Str = arr2.join('')
    return arr1Str == arr2Str
}

export function readURIFromFile(file: File){
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = function(ev: any){
            resolve(ev.target.result)
        }
        reader.readAsDataURL(file);
    })
}

export function getRandId() {
    return Math.floor(100000000 + Math.random() * 900000000);
}

export function getKeyByValue(object: any, value: any) {
    return Object.keys(object).find(key => object[key] === value);
}

export function checkPostCodeFormat(zipCode: string): Boolean {
    if (zipCode.trim().length == 0) {
        return true
    }

    return /(^\d{5}$)|(^\d{5}-\d{4}$)|(^\d{4}-\d{4}$)|(^\d{3}-\d{4}$)/.test(zipCode)
}

interface Account {
    contract_end_date: string,
    credit_consumed: number,
    credit_limit: number,
}
export function isExpiredUser({contract_end_date, credit_consumed, credit_limit} : Account) {
    if (!contract_end_date) {
        return false
    }

    if (credit_consumed >= credit_limit) {
        return true
    }

    const toDay = new Date();
    toDay.setHours(0,0,0,0);
    const contractDate = new Date(contract_end_date);
    return contractDate.getTime() < toDay.getTime()
}

export function calPlanLimits(realEstateName: string, planId: string) {
    if (!PlanTypeLimits[realEstateName]) {
        return ''
    }
    const PlanTypeFlipped = Object.entries(PlanTypeRealEstateType[realEstateName]).map(([key, value]) => [value, key]);
    const planDetail = Object.fromEntries(PlanTypeFlipped)
    if (!planDetail[planId]) {
        return ''
    }

    const planeName = planDetail[planId]
    //['Distribution]['Pro'] = 300
    return PlanTypeLimits[realEstateName][planeName]
}
