import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {editingUserActions} from '../reducers/editingUser'
import DatePicker from "react-datepicker"
import {EditablePlanTypes, PlanTypeRealEstateType, RealEstateType, PREmailAvailType} from "../constants"
import {formatDateDtAPIPost} from '../libs'
import {DropdownIcon, ShowPassword, HidePassword, EditPassword} from './SVGIcons'

function mapStateToProps(state: any) {
  return { ...state.editingUser }
}

const UserEditForm = (props: any) => {
  const {
    editingPage,
    email,
    password,
    showPassword,
    planType,
    creditLimit,
    corporateName,
    storeName,
    picName,
    postCode,
    address,
    contractStart,
    contractEnd,
    prEmailAvail,
    registrationRoute,
    marketingChannel,
    remarks,
    realEstateType,
    showHashPassword,
    errors,
  } = props
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()

  const cancel = () => {
    navigate("/")
  }
  const submitForm = () => {
    dispatch(editingUserActions.submitForm(navigate))
  }
  const switchShowPassword = () => {
    dispatch(editingUserActions.switchShowPassword())
}
  const setRealEstateType = (e: any) => {
    dispatch(editingUserActions.setRealEstateType(e.target.value))
    dispatch(editingUserActions.setPlanType(''))
    dispatch(editingUserActions.calcCreditLimit())
  }
  const setPlanType = (e: any) => {
    dispatch(editingUserActions.setPlanType(e.target.value))
    dispatch(editingUserActions.calcCreditLimit())
  }
  const setContractStart = (date: Date | null) => {
    if (!date)
      dispatch(editingUserActions.setContractStart(''))
    else
      dispatch(editingUserActions.setContractStart(formatDateDtAPIPost(date)))
  }
  const setContractEnd = (date: Date | null) => {
    if (!date)
      dispatch(editingUserActions.setContractEnd(''))
    else
      dispatch(editingUserActions.setContractEnd(formatDateDtAPIPost(date)))
  }

  const setShowHashPassword = () => {
    dispatch(editingUserActions.setShowHashPassword(false))
  }

  return (
    <div className="container mx-auto my-3 pb-2 bg-white rounded-lg text-[#2d2d2d]">
      <h1 className="text-xl rounded-t-lg px-3 py-2 bg-[#5571ad] text-white">
        <b>{editingPage == 'create' ? 'ユーザ登録' : '編集画面'}</b>
      </h1>
      <table className="min-w-full border-collapse bg-white">
        <colgroup>
          <col className="max-w-80 w-80"/>
          <col/>
        </colgroup>
        <tbody>
        <tr>
          <th className="bg-[#778ebe] border border-y-gray-200 px-3 py-2 text-white w-80">法人名<span className="text-red-600 ml-1">*</span>
          </th>
          <td className="border border-y-gray-300 p-3">
            <input type="text" value={corporateName} onChange={e => dispatch(editingUserActions.setCorporateName(e.target.value))}
              className="block w-full rounded-md border-0 p-1.5 ring-1 ring-gray-300 placeholder:text-gray-400 text-md"/>
            {errors["corporateName"] && (
              <p className="mt-2 text-sm text-red-600"><span className="font-medium">※</span>{errors["corporateName"]}</p>
            )}
          </td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-y-gray-200 px-3 py-2 text-white w-80">店舗名<span className="text-red-600 ml-1">*</span>
          </th>
          <td className="border border-y-gray-300 p-3">
            <input type="text" value={storeName} onChange={e => dispatch(editingUserActions.setStoreName(e.target.value))}
              className="block w-full rounded-md border-0 p-1.5 ring-1 ring-gray-300 placeholder:text-gray-400 text-md"/>
            {errors["storeName"] && (
              <p className="mt-2 text-sm text-red-600"><span className="font-medium">※</span>{errors["storeName"]}</p>
            )}
          </td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-y-gray-200 px-3 py-2 text-white w-80">担当者氏名</th>
          <td className="border border-y-gray-300 p-3">
            <input type="text" value={picName} onChange={e => dispatch(editingUserActions.setPICName(e.target.value))}
              className="block w-full rounded-md border-0 p-1.5 ring-1 ring-gray-300 placeholder:text-gray-400 text-md" />
            {errors["picName"] && (
              <p className="mt-2 text-sm text-red-600"><span className="font-medium">※</span>{errors["picName"]}</p>
            )}
          </td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-y-gray-200 px-3 py-2 text-white w-80">郵便番号</th>
          <td className="border border-y-gray-300 p-3">
            <input type="text" value={postCode} onChange={e => dispatch(editingUserActions.setPostCode(e.target.value))}
              className="block w-full rounded-md border-0 p-1.5 ring-1 ring-gray-300 placeholder:text-gray-400 text-md" />
            {errors["postCode"] && (
              <p className="mt-2 text-sm text-red-600"><span className="font-medium">※</span>{errors["postCode"]}</p>
            )}
          </td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-y-gray-200 px-3 py-2 text-white w-80">住所</th>
          <td className="border border-y-gray-300 p-3">
            <input type="text" value={address} onChange={e => dispatch(editingUserActions.setAddress(e.target.value))}
              className="block w-full rounded-md border-0 p-1.5 ring-1 ring-gray-300 placeholder:text-gray-400 text-md" />
            {errors["address"] && (
              <p className="mt-2 text-sm text-red-600"><span className="font-medium">※</span>{errors["address"]}</p>
            )}
          </td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-y-gray-200 px-3 py-2 text-white w-80">メールアドレス<span
              className="text-red-600 ml-1">*</span>
          </th>
          <td className="border border-y-gray-300 p-3">
            <input value={email} onChange={e => dispatch(editingUserActions.setEmail(e.target.value))}
              className="block w-full rounded-md border-0 p-1.5 ring-1 ring-gray-300 placeholder:text-gray-400 text-md" />
            {errors["email"] && (
              <p className="mt-2 text-sm text-red-600"><span className="font-medium">※</span>{errors["email"]}</p>
            )}
          </td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-y-gray-200 px-3 py-2 text-white w-80">パスワード<span className="text-red-600 ml-1">*</span></th>
          <td className="border border-y-gray-300 p-3 relative">
            {(editingPage == 'update' && showHashPassword) && (
              <div>**********
                <button type="button" onClick={setShowHashPassword} className="py-1 px-3 rounded border border-blue-700 text-blue-700 bg-white hover:text-white hover:bg-blue-700 whitespace-nowrap" title="パスワード編集">
                  <EditPassword />
                </button>
              </div>
            )}
            {(editingPage == 'create' || !showHashPassword ) && (
              <div className="relative w-80">
                {!showPassword && (
                  <input type="password" value={password}  onChange={e => dispatch(editingUserActions.setPassword(e.target.value))} autoComplete="off"
                    className="block w-full rounded-md border-0 p-1.5 ring-1 ring-gray-300 placeholder:text-gray-400 text-md pr-10 w-full" placeholder="••••••••"/>
                )}
                {showPassword && (
                  <input value={password} onChange={e => dispatch(editingUserActions.setPassword(e.target.value))}
                    className="block w-full rounded-md border-0 p-1.5 ring-1 ring-gray-300 placeholder:text-gray-400 text-md pr-10 w-full" autoComplete="off" placeholder="••••••••"/>
                )}
                <button type="button" onClick={switchShowPassword} className="absolute top-[0.2rem] bottom-[0.2rem] right-[0.2rem] rounded-md flex items-center px-2 bg-white">
                  {!showPassword && (
                    <ShowPassword />
                  )}
                  {showPassword && (
                    <HidePassword />
                  )}
                </button>
              </div>
            )}
            {errors["password"] && (
              <p className="mt-2 text-sm text-red-600"><span className="font-medium">※</span>{errors["password"]}</p>
            )}
          </td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-y-gray-200 px-3 py-2 text-white w-80">不動産種別<span
              className="text-red-600 ml-1">*</span>
          </th>
          <td className="border border-y-gray-300 p-3">
            <div className="grid">
              <DropdownIcon />
              <select value={realEstateType} onChange={setRealEstateType}
                className="block w-full rounded-md border-0 p-1.5 ring-1 ring-gray-300 placeholder:text-gray-400 text-md appearance-none row-start-1 col-start-1">
                <option value={''}></option>
                {Object.keys(RealEstateType).map((key) => (
                  <option key={key} value={(RealEstateType as any)[key]}>{key}</option>
                ))}
              </select>
            </div>
            {errors["realEstateType"] && (
              <p className="mt-2 text-sm text-red-600"><span className="font-medium">※</span>{errors["realEstateType"]}</p>
            )}
          </td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-y-gray-200 px-3 py-2 text-white w-80">利用プラン<span
              className="text-red-600 ml-1">*</span>
          </th>
          <td className="border border-y-gray-300 p-3">
            <div className="grid">
              <DropdownIcon />
              <select value={planType} onChange={setPlanType}
                className="block w-full rounded-md border-0 p-1.5 ring-1 ring-gray-300 placeholder:text-gray-400 text-md appearance-none row-start-1 col-start-1">
                <option value={''}></option>
                {(PlanTypeRealEstateType as any)[realEstateType] && Object.keys((PlanTypeRealEstateType as any)[realEstateType]).map((key) => (
                  <option key={key} value={((PlanTypeRealEstateType as any)[realEstateType] as any)[key]}>{key}</option>
                ))}
              </select>
            </div>
            {errors["planType"] && (
              <p className="mt-2 text-sm text-red-600"><span className="font-medium">※</span>{errors["planType"]}</p>
            )}
          </td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-y-gray-200 px-3 py-2 text-white w-80">上限回数<span
              className="text-red-600 ml-1">*</span>
          </th>
          <td className="border border-y-gray-300 p-3">
            <div className="relative">
              {EditablePlanTypes.includes(planType) && (
                <input value={creditLimit} onChange={e => dispatch(editingUserActions.setCreditLimit(e.target.value))}
                  className="block w-full rounded-md border-0 p-1.5 ring-1 ring-gray-300 placeholder:text-gray-400 text-md"/>                
              )}
              {!EditablePlanTypes.includes(planType) && (
                <input value={creditLimit} disabled
                  className="block w-full rounded-md border-0 p-1.5 ring-1 ring-gray-300 placeholder:text-gray-400 text-md"/>
              )}
              {errors["creditLimit"] && (
                <p className="mt-2 text-sm text-red-600"><span className="font-medium">※</span>{errors["creditLimit"]}</p>
              )}
            </div>
          </td>
        </tr>
          <tr>
            <th className="bg-[#778ebe] border border-y-gray-200 px-3 py-2 text-white w-80">契約開始日<span
                className="text-red-600 ml-1">*</span>
            </th>
            <td className="border border-y-gray-300 p-3">
              <div className="relative">
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  selected={contractStart} onChange={setContractStart} 
                  className="block w-full rounded-md border-0 p-1.5 ring-1 ring-gray-300 placeholder:text-gray-400 text-md"/>
              </div>
              {errors["contractStart"] && (
                <p className="mt-2 text-sm text-red-600"><span className="font-medium">※</span>{errors["contractStart"]}</p>
              )}
            </td>
          </tr>
          <tr>
            <th className="bg-[#778ebe] border border-y-gray-200 px-3 py-2 text-white w-80">契約終了日</th>
            <td className="border border-y-gray-300 p-3">
              <div className="relative">
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  selected={contractEnd} onChange={setContractEnd}
                  className="block w-full rounded-md border-0 p-1.5 ring-1 ring-gray-300 placeholder:text-gray-400 text-md"/>
              </div>
              {errors["contractEnd"] && (
                <p className="mt-2 text-sm text-red-600"><span className="font-medium">※</span>{errors["contractEnd"]}</p>
              )}
            </td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-y-gray-200 px-3 py-2 text-white w-80">販促メール<span
            className="text-red-600 ml-1">*</span></th>
          <td className="border border-y-gray-300 p-3">
            <div className="grid">
              <DropdownIcon />
              <select value={prEmailAvail} onChange={e => dispatch(editingUserActions.setPrEmailAvail(e.target.value))}
                className="block w-full rounded-md border-0 p-1.5 ring-1 ring-gray-300 placeholder:text-gray-400 text-md appearance-none row-start-1 col-start-1">
                <option value={''}></option>
                {Object.keys(PREmailAvailType).map((key) => (
                  <option key={key} value={(PREmailAvailType as any)[key]}>{key}</option>
                ))}
              </select>
            </div>
            {errors["prEmailAvail"] && (
                <p className="mt-2 text-sm text-red-600"><span className="font-medium">※</span>{errors["prEmailAvail"]}</p>
              )}
          </td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-y-gray-200 px-3 py-2 text-white w-80">登録経路</th>
          <td className="border border-y-gray-300 p-3">
            <input type="text" value={registrationRoute} onChange={e => dispatch(editingUserActions.setRegistrationRoute(e.target.value))}
              className="block w-full rounded-md border-0 p-1.5 ring-1 ring-gray-300 placeholder:text-gray-400 text-md" />
            {errors["registrationRoute"] && (
              <p className="mt-2 text-sm text-red-600"><span className="font-medium">※</span>{errors["registrationRoute"]}</p>
            )}
          </td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-y-gray-200 px-3 py-2 text-white w-80">備考</th>
          <td className="border border-y-gray-300 p-3">
            <textarea rows={5} value={remarks} onChange={e => dispatch(editingUserActions.setRemarks(e.target.value))}
              className="block w-full rounded-md border-0 p-1.5 ring-1 ring-gray-300 placeholder:text-gray-400 text-md" />
            {errors["remarks"] && (
              <p className="mt-2 text-sm text-red-600"><span className="font-medium">※</span>{errors["remarks"]}</p>
            )}
          </td>
        </tr>
        </tbody>
      </table>

      <div className="flex items-center justify-end gap-2 p-3 text-center">
        <button onClick={cancel} className="py-2.5 px-5 text-sm font-medium focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:ring-gray-100 min-w-28">戻る</button>
        <button onClick={submitForm} className="py-2.5 px-5 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700  whitespace-nowrap min-w-28">登録</button>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(UserEditForm)
