import {ThunkDispatch} from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import {connect, useDispatch } from "react-redux"
import {currentUserActions} from '../reducers/currentUser'
import { useEffect } from "react";

interface  ProtectedPageType {
    children: any,
}

function mapStateToProps(state: any) {
    return { ...state.currentUser }
}

const ProtectedPage = (props: ProtectedPageType) => {
    const {email, children} = props as any
    const navigate = useNavigate();
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    useEffect(() => {
        dispatch(currentUserActions.getCurrentUser(navigate))
    }, []);
    if (email) return children;

    return null
};

export default connect(mapStateToProps)(ProtectedPage)