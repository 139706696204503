import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import NGphoto from '../../assets/images/NG_all.png'

function mapStateToProps(state: any) {
  return {}
}

const ExamplePhotoNG = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()

  return (
    <div className="col-1 border-s border-gray-300">
      <div className="overflow-hidden bg-white rounded"><img src={NGphoto} alt="写真の撮り方" className=" w-full mx-auto"/></div>
    </div>
  );
};

export default connect(mapStateToProps)(ExamplePhotoNG)