import { useEffect } from "react"
import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import {useNavigate} from 'react-router-dom'
import { Modal } from "flowbite-react";
import {CloseModalIcon, ConfirmGenerateAfterPhotoIcon} from './SVGIcons'
import {roomDesignActions} from '../reducers/roomDesign'
import {selectCanRegenAfterImages} from '../selectors'

function mapStateToProps(state: any) {
  const canRegenAfterImages = selectCanRegenAfterImages(state)
  return { ...state.roomDesign, ...state.currentUser, canRegenAfterImages }
}

const RegenRoomDesignConfirm = (props: any) => {
  const {
    isRegenPopupShow,
    canRegenAfterImages,
  } = props
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()
  const closePopup = () => {
    dispatch(roomDesignActions.setIsRegenPopupShow(false))
  }
  const regenAfterImages = () => {
    if (!canRegenAfterImages) return

    dispatch(roomDesignActions.setIsRegenPopupShow(false))
    dispatch(roomDesignActions.regenAfterImages(navigate))
  }

  return (
    <>
      <Modal show={isRegenPopupShow} onClose={closePopup} size="md">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <button type="button" onClick={closePopup}
                className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center">
                <CloseModalIcon />
                <span className="sr-only">閉じる</span>
              </button>
              <div className="p-4 md:p-5">
                <ConfirmGenerateAfterPhotoIcon />
                <h3 className="text-base font-semibold leading-6 text-gray-900">再生成すると現在表示されている3枚の画像は削除されますが、<br/>
                よろしいでしょうか。<br/>
                再生成では生成回数は消費されません。</h3>
                <div className="flex flex-row-reverse items-center mt-6 space-x-4">
                <button type="button" onClick={regenAfterImages}
                    className="sm:ml-3 w-28 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">OK</button>
                  <button type="button" onClick={closePopup}
                    className="sm:ml-3 w-28 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100">キャンセル</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(RegenRoomDesignConfirm)
