import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {SiteConfService} from '../api'
import {toastActions} from './toast'

const getSiteNoti = createAsyncThunk(
    'getSiteNoti',
    async (_, thunkAPI: any) => {
        const res = await SiteConfService.getSiteConf({key: 'site_noti'})
        if (!res.key) return
        thunkAPI.dispatch(siteNotiActions.setNoti(res.value))
    }
)
const postSiteNoti = createAsyncThunk(
    'postSiteNoti',
    async (_, thunkAPI: any) => {
        const {noti} = thunkAPI.getState().siteNoti
        const res = await SiteConfService.updateSiteConf({requestBody: {key: 'site_noti', value: noti}})
        if (!res.success) {
            thunkAPI.dispatch(toastActions.toastError(res.message))
            return
        }

        thunkAPI.dispatch(toastActions.toastSuccess('通知内容が更新されました。'))//Site notification is saved.
        thunkAPI.dispatch(siteNotiActions.setInitState())
    }
)

const initialState = {
    isPopupShow: false,
    noti: '',
}

const siteNotiSlice = createSlice({
    name: 'siteNoti',
    initialState,
    reducers: {
        setNoti: (state, action) => {
            state.noti = action.payload
        },
        setIsPopupShow: (state, action) => {
            state.isPopupShow = action.payload
        },
        setInitState: (state) => {
            return initialState
        },
    },
})

const siteNotiActions = {
    ...siteNotiSlice.actions,
    getSiteNoti,
    postSiteNoti,
}
export {siteNotiActions}
export default siteNotiSlice.reducer