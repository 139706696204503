import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {roomDesignActions} from '../reducers/roomDesign'
import {selectCanCreateRoomDesign, selectCanConfirmReloadRoomDesign, selectCanRegenAfterImages} from '../selectors'
import {GenerateAfterPhotoIcon, ReloadRoomDesignIcon} from './SVGIcons'

function mapStateToProps(state: any) {
  const canCreateRoomDesign = selectCanCreateRoomDesign(state)
  const canConfirmReloadRoomDesign = selectCanConfirmReloadRoomDesign(state)
  const canRegenAfterImages = selectCanRegenAfterImages(state)
  return { ...state.roomDesign, canCreateRoomDesign, canConfirmReloadRoomDesign, canRegenAfterImages}
}

const RoomDesignEditActionButtons = (props: any) => {
  const {
    id,
    isGeneratedFail,
    beforeImages,
    canCreateRoomDesign,
    canConfirmReloadRoomDesign,
    canRegenAfterImages,
  } = props
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()
  const confirmCreateRoomDesign = () => {
    if (!canCreateRoomDesign) return

    dispatch(roomDesignActions.setIsConfirmPopupShow(true))
  }

  const confirmRegenAfterImages = () => {
    if (!canRegenAfterImages) return

    dispatch(roomDesignActions.setIsRegenPopupShow(true))
  }

  const confirmClearAll = () => {
    if (!canConfirmReloadRoomDesign) return

    dispatch(roomDesignActions.setIsClearAllPopupShow(true))
  }

  return (
    <div className="grid grid-cols-9 gap-4 mt-3">
      <div className="col-span-4 col-start-2 space-y-2">
        <button type="button" onClick={confirmCreateRoomDesign} disabled={!(canCreateRoomDesign && !isGeneratedFail)}
          className={canCreateRoomDesign && !isGeneratedFail
            ? "px-5 py-2.5 flex items-center gap-1 rounded-full mx-auto text-sm text-white hover:text-white bg-blue-600 hover:bg-blue-700"
            : "px-5 py-2.5 flex items-center gap-1 rounded-full mx-auto text-sm text-gray-900 bg-gray-400 disabled:opacity-75"}>
          <GenerateAfterPhotoIcon />
          バーチャルホームステージング生成
          <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
            {beforeImages.length}
          </span>
        </button>
      </div>
      {canConfirmReloadRoomDesign && (
        <div className="col-span-4 space-x-2 flex justify-center">
          {!id && (
            <button type="button" onClick={confirmCreateRoomDesign} disabled={!canCreateRoomDesign}
              className={canCreateRoomDesign
                ? "px-5 py-2.5 flex items-center gap-1 rounded-full text-sm text-white justify-center hover:text-white bg-sky-500/75 hover:bg-sky-500/100 w-full max-w-64"
                : "px-5 py-2.5 flex items-center gap-1 rounded-full text-sm text-white justify-center text-gray-900 bg-gray-400 disabled:opacity-75 w-full max-w-64"
              }>
              <ReloadRoomDesignIcon />
              再生成
            </button>
          )}
          {id && (
            <button type="button" onClick={confirmRegenAfterImages} disabled={!canRegenAfterImages}
              className={canRegenAfterImages
                ? "px-5 py-2.5 flex items-center gap-1 rounded-full text-sm text-white justify-center hover:text-white bg-sky-500/75 hover:bg-sky-500/100 w-full max-w-64"
                : "px-5 py-2.5 flex items-center gap-1 rounded-full text-sm text-white justify-center text-gray-900 bg-gray-400 disabled:opacity-75 w-full max-w-64"
              }>
              <ReloadRoomDesignIcon />
              再生成
            </button>
          )}
          <button type="button" onClick={confirmClearAll} disabled={!canConfirmReloadRoomDesign}
            className={canConfirmReloadRoomDesign
              ? "px-5 py-2.5 flex items-center gap-1 rounded-full text-sm text-white justify-center hover:text-white bg-blue-600 hover:bg-blue-700 w-full max-w-64"
              : "px-5 py-2.5 flex items-center gap-1 rounded-full text-sm text-white justify-center text-gray-900 bg-gray-400 disabled:opacity-75 w-full max-w-64"
            }>
            <GenerateAfterPhotoIcon />
            新規画像を生成する
          </button>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(RoomDesignEditActionButtons)