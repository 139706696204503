import { useEffect } from "react"
import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import {useParams, useNavigate} from 'react-router-dom'
import StaffNavBar from './StaffNavBar'
import Toast from './Toast'
import UserViewDetail from "./UserViewDetail"
import {editingUserActions} from '../reducers/editingUser'

function mapStateToProps(state: any) {
  return { ...state.currentUser }
}

const UserView = (props: any) => {
  const {is_staff} = props
  if (!is_staff) return null
  const {id} = useParams()
  if (!id) return null
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(editingUserActions.setInitState())
    dispatch(editingUserActions.setEditingPage('update'))
    dispatch(editingUserActions.getEditUser({id: parseInt(id), navigate}))
}, []);

  return (
    <>
      <Toast />
      <StaffNavBar />
      <UserViewDetail />
    </>
  );
};

export default connect(mapStateToProps)(UserView)
